import React from 'react'
import HowItWorksContentDesktop from './HowItWorksContentDesktop'
import HowItWorksContentTablet from './HowItWorksContentTablet'
import HowItWorksContentMobile from './HowItWorksContentMobile'
import useHowItWorksContent from './useHowItWorksContent'

const HowItWorksContent = () => {
  const presentationalProps = useHowItWorksContent()
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <HowItWorksContentMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <HowItWorksContentTablet {...presentationalProps} />
  }
  return <HowItWorksContentDesktop {...presentationalProps} />
}

export default HowItWorksContent
