import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const CheckIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M21.8441 11.0312H20.3785C20.0598 11.0312 19.7566 11.1844 19.5691 11.4469L14.6566 18.2594L12.4316 15.1719C12.2441 14.9125 11.9441 14.7563 11.6223 14.7563H10.1566C9.95352 14.7563 9.83477 14.9875 9.95352 15.1531L13.8473 20.5531C13.9393 20.6815 14.0605 20.7861 14.201 20.8583C14.3415 20.9305 14.4971 20.9681 14.6551 20.9681C14.813 20.9681 14.9687 20.9305 15.1092 20.8583C15.2497 20.7861 15.3709 20.6815 15.4629 20.5531L22.0441 11.4281C22.166 11.2625 22.0473 11.0312 21.8441 11.0312V11.0312Z"
        fill={fillStyle || '#03341D'}
      />
      <path
        d="M16 2C8.26875 2 2 8.26875 2 16C2 23.7313 8.26875 30 16 30C23.7313 30 30 23.7313 30 16C30 8.26875 23.7313 2 16 2ZM16 27.625C9.58125 27.625 4.375 22.4188 4.375 16C4.375 9.58125 9.58125 4.375 16 4.375C22.4188 4.375 27.625 9.58125 27.625 16C27.625 22.4188 22.4188 27.625 16 27.625Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default CheckIcon
