import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { CheckFilledIcon, CheckIcon } from '@flock/shared-ui'
import { HowItWorksContentPresentationalProps } from './howItWorksContentTypes'

import HowItWorksDiagram from '../../../images/how-it-works-diagram.svg'

const HowItWorksContentTablet = (
  props: HowItWorksContentPresentationalProps
) => {
  const { steps } = props
  return (
    <>
      <Grid container item sm={12}>
        <Typography variant="h1" color="moneyGreen.main">
          Here&apos;s how Flock makes it work.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box pt="16px" />
        <Typography variant="h2" color="gray8.main">
          The 721 exchange, made accessible to landlords.
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center">
        <Box component="img" src={HowItWorksDiagram} />
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          height="204px"
          justifyContent="space-between"
        >
          <Typography variant="p1" color="gray8.main">
            Section 721 of the US Internal Revenue Code (“the 721 exchange”) is
            a tax strategy that has been used by Wall Street investors since the
            1950s.
          </Typography>
          <Typography variant="p1" color="gray8.main">
            Flock Homes is the first to bring it to individual investors. With
            Flock, landlords can use the 721 exchange to seamlessly exchange
            their rental properties, tax-free, for direct ownership in a
            residential real estate fund.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pt="16px" />
        <Typography variant="h2" color="gray8.main">
          What is the process?
        </Typography>
      </Grid>
      {steps?.map((step, idx) => (
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            borderRadius="16px"
            sx={{
              backgroundColor: idx < 4 ? '#FFFFFF50' : 'green0.main',
              boxShadow: '0px 8px 24px 0px rgba(69, 73, 77, 0.08)',
            }}
          >
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                padding="32px 32px 0px 32px"
                height="180px"
                justifyContent="space-between"
              >
                <Typography variant="cta2" color="green3.main">
                  STEP {idx + 1}
                </Typography>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Typography
                    variant="p1"
                    color="moneyGreen.main"
                    sx={{ fontSize: '32px', lineHeight: '40px' }}
                  >
                    {step.title}
                  </Typography>
                  <Typography variant="p1" color="gray8.main">
                    {step.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box component="img" src={step.img} height="304px" />
            </Grid>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Box pt="16px" />
        <Typography variant="h2" color="gray8.main">
          Keep the benefits of real estate, without the <br /> hassle.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="16px"
          padding="32px"
          gap="16px"
          sx={{ backgroundColor: '#FFFFFF50' }}
        >
          <Typography
            sx={{ fontSize: '32px', lineHeight: '40px' }}
            color="gray8.main"
          >
            Seamless Exchange
          </Typography>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" gap="8px">
              <CheckIcon width="32px" height="32px" />
              <Typography variant="h4" color="gray8.main">
                Collaborative, streamlined transaction
              </Typography>
            </Box>
            <Box display="flex" gap="8px">
              <CheckIcon width="32px" height="32px" />
              <Typography variant="h4" color="gray8.main">
                No qualified intermediaries necessary
              </Typography>
            </Box>
            <Box display="flex" gap="8px">
              <CheckIcon width="32px" height="32px" />
              <Typography variant="h4" color="gray8.main">
                No identification or exchange periods
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="16px"
          padding="32px"
          gap="16px"
          sx={{ backgroundColor: 'green0.main' }}
        >
          <Typography
            sx={{ fontSize: '32px', lineHeight: '40px' }}
            color="gray8.main"
          >
            Effortless Ownership
          </Typography>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" gap="8px">
              <CheckFilledIcon width="32px" height="32px" />
              <Typography variant="h4" color="gray8.main">
                Easy and reliable earnings
              </Typography>
            </Box>
            <Box display="flex" gap="8px">
              <CheckFilledIcon width="32px" height="32px" />
              <Typography variant="h4" color="gray8.main">
                Transparent and detailed reporting
              </Typography>
            </Box>
            <Box display="flex" gap="8px">
              <CheckFilledIcon width="32px" height="32px" />
              <Typography variant="h4" color="gray8.main">
                Flexible tax and estate planning
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default HowItWorksContentTablet
