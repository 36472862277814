import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const CheckFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16 2C8.26875 2 2 8.26875 2 16C2 23.7313 8.26875 30 16 30C23.7313 30 30 23.7313 30 16C30 8.26875 23.7313 2 16 2ZM22.0469 11.4281L15.4656 20.5531C15.3736 20.6815 15.2524 20.7861 15.1119 20.8583C14.9714 20.9305 14.8157 20.9681 14.6578 20.9681C14.4999 20.9681 14.3442 20.9305 14.2037 20.8583C14.0632 20.7861 13.942 20.6815 13.85 20.5531L9.95312 15.1531C9.83437 14.9875 9.95312 14.7563 10.1562 14.7563H11.6219C11.9406 14.7563 12.2437 14.9094 12.4312 15.1719L14.6562 18.2594L19.5688 11.4469C19.7563 11.1875 20.0562 11.0312 20.3781 11.0312H21.8438C22.0469 11.0312 22.1656 11.2625 22.0469 11.4281Z"
        fill={fillStyle || '#82878C'}
      />
      <path
        d="M16 2C8.26875 2 2 8.26875 2 16C2 23.7313 8.26875 30 16 30C23.7313 30 30 23.7313 30 16C30 8.26875 23.7313 2 16 2ZM22.0469 11.4281L15.4656 20.5531C15.3736 20.6815 15.2524 20.7861 15.1119 20.8583C14.9714 20.9305 14.8157 20.9681 14.6578 20.9681C14.4999 20.9681 14.3442 20.9305 14.2037 20.8583C14.0632 20.7861 13.942 20.6815 13.85 20.5531L9.95312 15.1531C9.83437 14.9875 9.95312 14.7563 10.1562 14.7563H11.6219C11.9406 14.7563 12.2437 14.9094 12.4312 15.1719L14.6562 18.2594L19.5688 11.4469C19.7563 11.1875 20.0562 11.0312 20.3781 11.0312H21.8438C22.0469 11.0312 22.1656 11.2625 22.0469 11.4281Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default CheckFilledIcon
